<template>
    <div class="tw-bg-blue-100 tw-p-8 tw-rounded-md tw-shadow-lg tw-flex tw-flex-col tw-items-center tw-max-w-3xl tw-break-all">
        <svg class="tw-animate-spin tw-h-8 tw-w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="tw-opacity-25" cx="12" cy="12" r="10" stroke="var(--brand-color)" stroke-width="4"></circle>
            <path class="tw-opacity-75" fill="var(--brand-color)" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <div class="tw-text-base tw-mt-4">Please wait while redirecting ...</div>
    </div>
</template>
<script>
import axios from "../../axios/user";
import {decodeToken, validateTokenLocal, validateTokenRemote} from "@/utils/auth";
import {fetchWlpRefreshToken} from "@/utils/functions";
import store from "@/store";
import {mapActions, mapGetters} from "vuex";
import VueCookies from "vue-cookies";
export default {
    methods: {
        ...mapActions(["fetchPermissions", "fetchCaseValid", "fetchCaseFreezeDate"]),
        async getToken() {
            let payload = {code: this.$route.query.verifying_entity_id.split("=")[1], verifying_entity_id: this.$route.query.verifying_entity_id.split("?")[0]};
            const response = await axios.post("/api/v1/get-token-from-code", payload);
            if (response.data.token) {
                VueCookies.set("token", response.data.token);
                let decodedToken = decodeToken(response.data.token);
                let data1 = {decodedToken: decodedToken, token: response.data.token};
                this.$store.dispatch("setLoginSession", data1);
                await this.validateAndSetAuth(response.data.token);
            }
            if (response.data.wlp_token) {
                VueCookies.set("wlp_token", response.data.wlp_token);
            }
            if (response.data.wlp_frontend_url) {
                VueCookies.set("wlp_frontend_url", response.data.wlp_frontend_url);
            }
            if (response.data.wlp_backend_url) {
                VueCookies.set("wlp_backend_url", response.data.wlp_backend_url);
            }
        },
        async validateAndSetAuth(token) {
            let validatedToken = await validateTokenRemote(token);
            if (validatedToken?.error) {
                if (validatedToken.error.type === "invalid") {
                    store.dispatch("logoutUser");
                }
                if (validatedToken.error.type === "expired") {
                    try {
                        let decodedToken = decodeToken(token);
                        let decodedRefreshtoken;

                        if (Object.keys(decodedToken).length > 0) {
                            if (decodedToken.session_src === "neotaswlp") {
                                const _token = await fetchWlpRefreshToken(token);
                                decodedRefreshtoken = decodeToken(_token);
                            }
                        }
                        store.dispatch("setLoginSession", {decodedRefreshtoken, token: _token});
                    } catch (error) {
                        // if refresh token throws an error logout the user
                        console.error(error);
                        // store.dispatch("logoutUser");
                    }
                }
            } else {
                // token is valid
                const tokenVerifiedByLocalCert = validateTokenLocal(token);
                if (tokenVerifiedByLocalCert) {
                    let decodedToken = decodeToken(token);
                    store.dispatch("setLoginSession", {decodedToken, token: token});

                    var body = {
                        id: decodedToken.account_id,
                    };
                    await axios.post("/api/v1/switch-account", body);
                    this.$store.commit("setAccountId", {accountId: decodedToken.account_id});
                    VueCookies.set("ACCOUNT_ID", decodedToken.account_id);
                    VueCookies.set("token", token, null, null, null, "true", "none");
                    await this.$store.dispatch("getCaseListReadOnly");
                    this.$store.commit("setCase", {caseId: this.$route.query.case_id});
                    this.$store.dispatch("setCaseId", this.$route.query.case_id);
                    if (this.getCaseId) {
                        await this.fetchCaseValid();
                        await this.fetchCaseFreezeDate();
                    }
                    // await this.fetchPermissions();
                    this.$router.push(`/research/${this.$route.query.case_id}`);
                }
            }
        },
    },
    async mounted() {
        await this.getToken();
    },
    computed: {
        ...mapGetters(["getCaseId"]),
    },
};
</script>
